// Generated by Framer (596b9d9)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["W910zqtns"];

const variantClassNames = {W910zqtns: "framer-v-a73t42"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "W910zqtns", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "W910zqtns", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-B3rMp", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-a73t42", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"W910zqtns"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-1pvbqd2"} data-border data-framer-name={"Ellipse 14"} layoutDependency={layoutDependency} layoutId={"ky7x1h2yq"} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-e938dcc6-2a0e-4c12-9846-4efab72d34e8, rgb(228, 2, 70)) /* {\"name\":\"Red\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-B3rMp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-B3rMp * { box-sizing: border-box; }", ".framer-B3rMp .framer-1hlzpnv { display: block; }", ".framer-B3rMp .framer-a73t42 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 6px 0px 0px 0px; position: relative; width: min-content; }", ".framer-B3rMp .framer-1pvbqd2 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 16px); overflow: hidden; position: relative; width: 16px; will-change: transform; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-B3rMp .framer-a73t42 { gap: 0px; } .framer-B3rMp .framer-a73t42 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-B3rMp .framer-a73t42 > :first-child { margin-left: 0px; } .framer-B3rMp .framer-a73t42 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerhXDpOMLG_: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerhXDpOMLG_;

FramerhXDpOMLG_.displayName = "Archive / List point";

FramerhXDpOMLG_.defaultProps = {height: 22, width: 16};

addFonts(FramerhXDpOMLG_, [])